import React, {ReactElement, useState, useEffect, useRef, useMemo} from 'react';
import Head from 'next/head';
import Error from 'next/error';
import styled from 'styled-components';

import MainLayout from 'components/layout/mainLayout';
import PageWrapper from 'components/pageWrapper';
import ContentArea from 'components/contentArea';

import seo from 'utils/seo';

import getTemplateData from 'utils/getTemplateData';
import storyblokService from 'services/storyblokService.js';


type Props = {
  statusCode: number,
  error?: any,
  masterTemplateData: any,
  pageData: any
};

const Page: NextPageExtended<Props> = (props) => {

  // Get content from supplied StoryBlok story
  const content = useMemo(() => {
    return props.pageData && props.pageData.content && props.pageData.content.content ? props.pageData.content.content : null;
  }, [props.pageData])

  const seoContent = useMemo(() => {
    return props.pageData && props.pageData.content && props.pageData.content.seo ? props.pageData.content.seo : null;
  }, [props.pageData])
  

  // Page Load
  useEffect(() => {
    storyblokService.initEditor(null);
  }, [])


  // Errors
  if (props.statusCode > 299) {
    return <Error statusCode={props.statusCode} />;
  }

  
  return (

    <>

      <Head>
        <title>{seo.createTitle(seoContent?.title)}</title>
        <meta name="description" content={seoContent?.description} />
      </Head>

      <StyledPage className="homePage">
        <PageWrapper>
            <ContentArea content={content} />
        </PageWrapper>
      </StyledPage>
      
      {storyblokService.bridge()}

    </>
  
  );

}


// Server Side Props
export async function getServerSideProps({ params, preview = false, previewData = {} }) {

  let statusCode = 200;

  // Create full slug
  let slug = params.slug;
  slug = slug.join('/');


  // Redirects
  const redirects = [
    {from: 'information-centre/choosing-a-powerchair', to: '/information-centre/powered-wheelchairs', permanent: true},
    {from: 'information-centre/choosing-a-mobility-scooter', to: '/information-centre/mobility-scooters', permanent: true},
    {from: 'information-centre/choosing-a-mobility-scooter2', to: '/information-centre/mobility-scooters', permanent: true},
    {from: 'information-centre/choosing-a-powerchair2', to: '/information-centre/powered-wheelchairs', permanent: true},
    {from: 'information-centre/choosing-a-rise-and-recline-chair', to: '/information-centre/rise-and-recline-chairs', permanent: true},
    {from: 'information-centre/ot-assessment-service', to: '/information-centre/specialist-seating', permanent: true},
    {from: 'information-centre/mobility-scooters', to: '/category/mobility-scooters', permanent: true},
    {from: 'information-centre/powered-wheelchairs', to: '/category/powerchairs', permanent: true},
    {from: 'information-centre/manual-wheelchairs', to: '/category/wheelchairs', permanent: true},
    {from: 'information-centre/rise-and-recline-chairs', to: '/category/furniture', permanent: true},
    {from: 'information-centre/adjustable-beds', to: '/category/beds', permanent: true},
    {from: 'information-centre/rollators', to: '/category/walking-aids', permanent: true},
    {from: 'information-centre/adjustable-beds', to: '/category/beds', permanent: true},
    {from: 'information-centre/rollators', to: '/category/walking-aids', permanent: true},
    {from: 'information-centre/powered-mobility-hire', to: '/information-centre/mobility-scooter-hire', permanent: true}
  ];

  for (const redirect of redirects) {

    if(slug === redirect.from){

      return {
        redirect: {
          destination: redirect.to,
          permanent: redirect.permanent
        }
      }

    }

  }


  // Get Data
  let results;

  try {
    results = await Promise.all([
      getTemplateData(),
      storyblokService.get(`cdn/stories/${slug}`, {})
    ]);
  } catch (error) {
    return {
      // unstable_revalidate: 1, 
      props: { statusCode: error.response ? error.response.status : 500}
    };
  }

  const masterTemplateData = results[0] ?? null;
  const pageData = results[1]?.data?.story ?? null;

  // Hack because last items keeps going missing
  masterTemplateData.content.top_menu_categories.push({});

  // Return props
  return {
    // unstable_revalidate: 1,
    props: { 
      params,
      statusCode,
      masterTemplateData,
      pageData
    }
  };

}

Page.getLayout = (page: ReactElement) => {
  return (
    <MainLayout masterTemplateData={page.props.masterTemplateData}>
      {page}
    </MainLayout>
  );
}

export default Page;


// export async function getStaticPaths() {
//   return {
//     paths: [
//       { params: { slug: ['/'] } },
//       { params: { slug: ['test'] } },
//       { params: { slug: ['information-centre', 'about-us'] } },
//       { params: { slug: ['information-centre', 'health-professionals'] } },
//       { params: { slug: ['information-centre', 'faqs'] } },
//       { params: { slug: ['information-centre', 'suppliers'] } },
//       { params: { slug: ['information-centre', 'manual-wheelchair-hire-service '] } },
//     ],
//     fallback: true
//   };
// };



// Styles
const StyledPage = styled.div``;



